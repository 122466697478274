import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
@Injectable({
  providedIn: 'root'
})
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor() {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headersConfig = {
      //'Content-Type': 'application/json',
      // 'Accept': 'application/json'
    };
  
    const token = window.localStorage.getItem('TGJWTTOKEN');
    if (req.url.indexOf('https://paper-api.alpaca.markets/v2/') === 0) {
      headersConfig['APCA-API-KEY-ID'] = `${environment.AlpaKeyId}`,
        headersConfig['APCA-API-SECRET-KEY'] = `${environment.AlpaSecretKey}`
    } else if (token && req.url.indexOf('https://dev.echoapp.biz/portal/api/V1/') === 0) {
      
      headersConfig['Authorization'] = `Bearer ${token}`;
    } else if (token && req.url.indexOf('https://test.echoapp.biz/portal/api/V1/') === 0) {
     
      headersConfig['Authorization'] = `Bearer ${token}`;
    } else if (token && req.url.indexOf('https://api.echoapp.biz/api/V1/') === 0) {

      headersConfig['Authorization'] = `Bearer ${token}`;
    } 


    const request = req.clone({ setHeaders: headersConfig });
    return next.handle(request);
  }
}
